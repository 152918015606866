import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'focus-visible'

import { createRoot } from 'react-dom/client'
import SignInApp from './app.js'

const container = document.getElementById('cliniko-react')
const root = createRoot(container)
root.render(<SignInApp />)
