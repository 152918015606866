import * as F from 'modules/form'
import { useState } from 'react'
import * as S from '../../apps/cliniko/style'
import CheckboxAndSubmit from './checkbox-and-submit'
import Form from './form'
import { inputStyles } from './input-styles'
import Message from './message'
import PasskeyAuthButton from './passkey-auth-button'

const TwoFactorAuthForm = ({
  disabled: disabledIn,
  onSubmit,
  referralParam,
  state,
  hasPasskey,
  hasAuthenticator,
}) => {
  const [disabled, setDisabled] = useState(false)
  const message = hasAuthenticator
    ? 'Enter the verification code generated by your authenticator app.'
    : 'Your passkey is required to log in.'
  return (
    <Form disabled={disabled || disabledIn} onSubmit={onSubmit} referralParam={referralParam}>
      <Message style={hasAuthenticator ? {} : { ...S.legacy.space.mb(3.2) }}>{message}</Message>

      {!!hasAuthenticator && (
        <>
          <F.HiddenField defaultValue={state.current.email} name="user.email" />
          <F.HiddenField defaultValue={state.current.password} name="user.password" />
          <F.HiddenField defaultValue={state.current.authToken} name="auth_token" />
          <F.HiddenField defaultValue={state.current.userId} name="user_id" />
          <F.TextInput
            autoFocus
            css={inputStyles}
            label="Verification code"
            legacyStyle
            name="user.otp_attempt"
            required
          />
          <CheckboxAndSubmit state={state} />
        </>
      )}

      {!!hasAuthenticator && !!hasPasskey && (
        <div css={dividerStyles}>
          <span>or</span>
        </div>
      )}

      {!!hasPasskey && (
        <>
          <PasskeyAuthButton
            onSubmit={onSubmit}
            setFormDisabled={setDisabled}
            state={state}
            name="passkey_two_factor"
            twoFactor={true}>
            Verify with a passkey
          </PasskeyAuthButton>
        </>
      )}
    </Form>
  )
}

const dividerStyles = {
  display: 'flex',
  alignItems: 'center',
  color: '#fff',
  marginTop: S.rem(30),
  marginBottom: S.rem(30),

  '&::before, &::after': {
    content: '""',
    flex: 1,
    height: '1px',
    backgroundColor: '#ffffff88',
  },
  span: {
    padding: '0 10px',
  },
}

export default TwoFactorAuthForm
